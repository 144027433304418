import { Routes } from "@angular/router";
import { AdminLayoutComponent } from "./core";
import { AuthLayoutComponent } from "./core";
import { AuthGuardService as AuthGuard } from "./auth/auth-guard.service";

import { SuperAdminGuard } from "./auth/super-admin-guard.service";
// const user = JSON.parse(localStorage.getItem("user_details"));

export const AppRoutes: Routes = [
  { path: "", redirectTo: "customers", pathMatch: "full" },
  {
    path: "",
    component: AuthLayoutComponent,
    children: [
      {
        path: "",
        loadChildren: "./account/account.module#AccountModule",
      },
      {
        path: "account",
        loadChildren: "./account/account.module#AccountModule",
      },
    ],
  },
  {
    path: "",
    component: AdminLayoutComponent,
    canActivate:[AuthGuard],
    children: [
      {
        path: "",
        pathMatch: "prefix",
        redirectTo: "building-configuration",
      },
      {
        path: "communication",
        loadChildren:
          "./communication/communication.module#CommunicationModule",
          canActivate: [SuperAdminGuard],

      },
      {
        path: "recipient-groups",
        loadChildren:
          "./recipient-group/recipient-group.module#RecipientGroupModule",
          canActivate:[SuperAdminGuard]

      },
      {
        path: "building-configuration",
        loadChildren:
          "./building-configuration/building-configuration.module#BuildingConfigurationModule",
           canActivate:[SuperAdminGuard]

      },
      {
        path: "customers",
        loadChildren: "./customers/customers.module#CustomersModule",
      },
      {
        path: "internal-users",
        loadChildren:
          "./internal-users/internal-users.module#InternalUsersModule",
          canActivate:[SuperAdminGuard]

      },
      {
        path: "payment",
        loadChildren: "./payment/payment.module#PaymentModule",
      },
      {
        path: "report",
        loadChildren: "./report/report.module#ReportModule",
      },
      {
        path: "dashboard",
        loadChildren: "./dashboard/dashboard.module#DashboardModule",
      },
      {
        path: "refunds",
        loadChildren: "./refunds/refunds.module#RefundsModule",
      },
      {
        path: "pricing-configuration",
        loadChildren:
          "./pricing-configuration/pricing-configuration.module#PricingConfigurationModule",
          canActivate:[SuperAdminGuard]

      },
      {
        path: "meta-configuration",
        loadChildren:
          "./meta-configuration/meta-configuration.module#MetaConfigurationModule",
          canActivate:[SuperAdminGuard]
      },
      {
        path: "agreement-template",
        loadChildren:
          "./agreement-template/agreement-template.module#AgreementTemplateModule",
          canActivate:[SuperAdminGuard]

      },
      {
        path: "profile",
        loadChildren: "./profile/profile.module#ProfileModule",
      },

      {
        path: "notice-board",
        loadChildren: "./notice-board/notice-board.module#NoticeBoardModule",
        canActivate:[SuperAdminGuard]
      },
      {
        path: "cleanup",
        loadChildren: "./cleanup/cleanup.module#CleanupModule",
        canActivate:[SuperAdminGuard]
      },
      { path: "**", redirectTo: "/login" },
    ],
  },
  {
    path: "",
    component: AuthLayoutComponent,
    children: [
      {
        path: "",
        loadChildren: "./account/account.module#AccountModule",
      },
      {
        path: "account",
        loadChildren: "./account/account.module#AccountModule",
      },
    ],
  },
];
