import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
/* common modules */
import { TabsModule } from 'ngx-tabset';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { Ng5SliderModule } from 'ng5-slider';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
//import { NgxPaginationModule } from 'ngx-pagination';
//import { GrdFilterPipe } from '../filters/grd-filter.pipe';
//import { SanitizeHtmlPipe } from '../filters/sanitize-html.pipe';
//import { DataTablesModule } from 'angular-datatables';
//import { BookinghistoryComponent } from './bookinghistory/bookinghistory.component';
import { MomentModule } from 'ngx-moment';
import {ConfirmModalComponent} from '../shared/components/confirm-modal/confirm-modal.component';
import {NgbModalModule, NgbModalRef, NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {ConfirmModalService} from '../shared/services/ConfirmModal/confirm-modal.service';
import {AngularFontAwesomeModule} from 'angular-font-awesome';

@NgModule({
  declarations: [
    ConfirmModalComponent
  ],
  imports: [
    CommonModule,
    NgbModule,
    NgbModalModule,
    //NgxSmartModalModule.forRoot(),
    Ng5SliderModule,
    TabsModule.forRoot(),
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    FormsModule,
    ReactiveFormsModule,
   // NgxPaginationModule,
   // DataTablesModule,
    MomentModule
  ],
  exports: [
   // NgxSmartModalModule,
    Ng5SliderModule,
    TabsModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    FormsModule,
    ReactiveFormsModule,
    //NgxPaginationModule,
    //GrdFilterPipe,
    //SanitizeHtmlPipe,
   // DataTablesModule,
   // BookinghistoryComponent,
    MomentModule,
    //CheckinpaymenthistoryComponent,
    //CheckoutpaymenthistoryComponent,
    //DepositbookingfeesComponent,
    //PaymentlogComponent
    ConfirmModalComponent,
    AngularFontAwesomeModule
  ],
  bootstrap: [
  ],
  entryComponents:[ConfirmModalComponent],
})
export class SharedModule { }
