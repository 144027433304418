import { BrowserModule } from "@angular/platform-browser";
import {
  NgModule,
  CUSTOM_ELEMENTS_SCHEMA,
  NO_ERRORS_SCHEMA,
} from "@angular/core";
import { AdminLayoutComponent } from "./core/admin-layout/admin-layout.component";
import { AuthLayoutComponent } from "./core/auth-layout/auth-layout.component";
import { AppComponent } from "./app.component";
import {
  HttpClientModule,
  HttpClient,
  HTTP_INTERCEPTORS,
} from "@angular/common/http";
import { AngularEditorModule } from "@kolkov/angular-editor";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AvatarModule } from "ngx-avatar";
import { AppRoutes } from "./app.routing";
import { CookieService } from "ngx-cookie-service";
import { ToastrModule } from "ngx-toastr";
import { IconSpriteModule } from "ng-svg-icon-sprite";
import { TabsModule } from "ngx-tabset";
import { NgbModalModule, NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { AngularFontAwesomeModule } from "angular-font-awesome";
import { FooterComponent } from "./core/footer/footer.component";
import { HeaderComponent } from "./core/header/header.component";
import { MenuComponent } from "./core/menu/menu.component";
import { SidebarComponent } from "./core/sidebar/sidebar.component";
import { RouterModule } from "@angular/router";
import { DataTablesModule } from "angular-datatables";
import { OwlDateTimeModule, OwlNativeDateTimeModule } from "ng-pick-datetime";
import { StorageServiceModule } from "angular-webstorage-service";
import { HttpModule } from "@angular/http";
import { JwtModule } from "@auth0/angular-jwt";
import { MyHttpInterceptor } from "./interceptor/my-http-interceptor";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { NgxSmartModalModule } from "ngx-smart-modal";
import { DatePipe } from "@angular/common";
import { NgxSelectModule, INgxSelectOptions } from "ngx-select-ex";
import { DataService } from "./dataservice/data.service";
import { SharedModule } from "./common/shared.module";
import { ChartsModule, ThemeService } from "ng2-charts";
import "chartjs-chart-box-and-violin-plot";
import "chartjs-chart-box-and-violin-plot/build/Chart.BoxPlot.js";
import { ChartModule } from "angular2-chartjs";

// import { UpdateWalletComponent } from "./cleanup/update-wallet/update-wallet.component";
// import { UpdateInvoiceAmountComponent } from "./cleanup/update-invoice-amount/update-invoice-amount.component";
// import { CleanupComponent } from './cleanup/cleanup.component';

//import { ReportComponent } from './report/report.component';
//import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

export function tokenGetter() {
  return localStorage.getItem("token");
}
@NgModule({
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    AuthLayoutComponent,
    FooterComponent,
    HeaderComponent,
    MenuComponent,
    SidebarComponent,
    // UpdateWalletComponent,
    // UpdateInvoiceAmountComponent,
    // ReportComponent,
  ],
  imports: [
    ChartsModule,
    ChartModule,
    HttpModule,
    NgxSelectModule,
    OwlDateTimeModule,
    StorageServiceModule,
    OwlNativeDateTimeModule,
    IconSpriteModule,
    AvatarModule,
    TabsModule.forRoot(),
    ToastrModule.forRoot({
      //   positionClass:'toast-bottom-right',
      timeOut: 1000,
      closeButton: true,
      preventDuplicates: true,
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    RouterModule.forRoot(AppRoutes, { useHash: false }),
    BrowserModule,
    NgbModule,
    NgbModalModule,
    HttpClientModule,
    AngularEditorModule,
    FormsModule,
    ReactiveFormsModule,
    AngularFontAwesomeModule,
    DataTablesModule,
    BrowserAnimationsModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    NgxSmartModalModule.forRoot(),
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        whitelistedDomains: ["http://localhost:4200"],
        blacklistedRoutes: ["http://localhost:4200/#/login"],
      },
    }),
    SharedModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
  providers: [
    CookieService,
    DataService,
    DatePipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MyHttpInterceptor,
      multi: true,
    },
    ThemeService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
