import { Component,  EventEmitter,  Output,  Input,  ViewChild,  OnInit,  ElementRef,  TemplateRef,  Inject } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LOCAL_STORAGE,  WebStorageService } from 'angular-webstorage-service';
import { Router } from '@angular/router';
import { DataService, Response } from '../../dataservice/data.service';
import { GlobalVariable } from '../../app.component';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from '../../services/common.service';
import { FormGroup, FormControl, Validators, FormBuilder , NgForm , FormsModule, ReactiveFormsModule} from '@angular/forms';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  private BASE_URL = GlobalVariable;
  managerAccess:any = [];
  userDetails: any;
  public show: boolean = false;
  avatorName: any;
 // branchname:any;
  branchForm: FormGroup;
  brid:any;

  constructor(private modalService:  NgbModal, @Inject(LOCAL_STORAGE) private storage:  WebStorageService,
    private router:  Router, private HttpService: DataService, public formBuilder: FormBuilder,
    private toastrService: ToastrService, private commonservice: CommonService) {   }

  ngOnInit() {
    this.managerAccess = this.storage.get('admin_access');
    this.userDetails = this.storage.get('user_details');
    this.avatorName = this.userDetails.name.substring(0,2);
    this.commonservice.userDetail.subscribe(res => {
      if (res !== null) {
        this.userDetails = res;
        this.avatorName = this.userDetails.name.substring(0,2);
      }
    });
    if (this.storage.get('select_branch')!==null) {
      this.brid = this.storage.get('select_branch');
    } else {
      this.brid = this.managerAccess[0].building_id.id;
    }
    this.branchForm = this.formBuilder.group({
      'branchname' : [this.brid, Validators.compose([Validators.required])]
    });

  
  }

  /**
  * Signout and redirect to sign in page
  * @memberof HeaderComponent
  */
 logout() {
  window.stop();
  this.router.navigate(['/login']);
  this.storage.remove('admin_access_token');
  this.storage.remove('user_details');
  this.storage.remove('admin_access');
  this.storage.remove('select_branch');
  this.storage.remove('checkinchecklist');
  this.storage.remove('student_id');

 }

 public toggle(): void {
  // window.stopPropagation();
  this.commonservice.toggle();
}

profileClick() {
  this.commonservice.profile(true);
  // this.router.navigate(['/cleanup']);
}
changebranch () {
  this.branchForm.patchValue({'branchname':this.branchForm.value.branchname});
  this.commonservice.changeBranch(this.branchForm.value.branchname);
  this.storage.set('select_branch', this.branchForm.value.branchname);
}

}
