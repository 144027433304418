import { Injectable, Inject } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateChild } from '@angular/router';
import { AuthService } from './auth.service';
import { LOCAL_STORAGE, WebStorageService} from 'angular-webstorage-service';

@Injectable({
  providedIn: 'root'
})
export class SuperAdminGuard implements CanActivate {
  userRole:any;
  constructor(public auth: AuthService, public router: Router, @Inject(LOCAL_STORAGE) private storage: WebStorageService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    this.userRole=this.storage.get('user_details');
    //console.log('this.userRole.user_role.id');

    if(this.auth.isAuthenticated() && this.userRole && this.userRole.user_role.id !==6){
        this.router.navigate(['/']);
        return false;
    }else{
        return true;
    }

  }
}
