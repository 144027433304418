import { Component } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { environment } from "../environments/environment";
import { AuthService } from "../app/auth/auth.service";
import { Router, RouterStateSnapshot, ActivatedRoute } from "@angular/router";
import { Location, LocationStrategy, PlatformLocation } from "@angular/common";
import { CommonService } from "../app/services/common.service";
import { LOCAL_STORAGE, WebStorageService } from "angular-webstorage-service";
//import { Spinkit } from 'ng-http-loader';
import { Title } from "@angular/platform-browser";

import { AngularEditorConfig } from "@kolkov/angular-editor";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent {
  title = "qwetuadminpanel";
}

export const GlobalVariable = Object.freeze({
  BASE_API_URL: environment.apiHost,

  /* (1) User Registration */
  REGISTER_URL: "/admin/register", //post
  LOGIN_URL: "/admin/login", //post
  FORGOTPASS_URL: "/forgotpass-otp", //post
  VERIFYOTP_URL: "/verify-otp", //post
  UPDATE_PASSWORD_URL: "/update-password", //post

  /* (2) User Profile */
  USER_PROFILE_ID: "/user-profile/", //put
  CHANGE_PASSWORD_URL: "/change-password",

  /* MetaData Routes */
  COUNTRY_METAS_URL: "/countries-list", //get
  USER_ROLE_METAS_URL: "/user-roles",
  BUILDING_METAS_URL: "/buildings",
  BUILDING_ACCOUNTS_URL: "/building-accounts",
  DOCUMENT_NAME_METAS_URL: "/document-name-list", //get
  DOCUMENT_TYPE_METAS_URL: "/document-type-list", //get
  METAS_URL: "/get-metas",
  PRICING_PLAN_METAS_URL: "/pricing-plan-status-meta",
  DOWNLOAD_PRICING_PLAN:"/admin/pricing-plans/export/",
  UPLOAD_PRICING_PLAN:"/admin/pricing-plans/import/",
  DISCOUNT_METAS_URL: "/discounts",
  PAYMENT_METHOD_METAS_URL: "/admin/payment-method-meta",
  INSTITUTION_METAS_URL: "/admin/institution-meta",
  PAYMENT_RECORD_METAS_URL: "/admin/payment-record-meta",
  REPORT_METAS_URL: "/admin/report-meta",
  TICKET_ISSUE_METAS_URL: "/admin/ticket-issue-meta",
  TICKET_LOCATION_METAS_URL: "/admin/ticket-location-meta",
  YEAR_OF_STUDY_METAS_URL: "/admin/year-of-study-meta",
  ADMIN_DISCOUNT_METAS_URL: "/admin/discount-meta",
  CHECKIN_CHANGE_URL: "/admin/user-bookings/checkindate",
  CHECKOUT_CHANGE_URL: "/admin/user-bookings",
  CANCEL_BOOKING: "/admin/applicants/",
  /**
   * REPORT
   */
  REPORT_META_URL: "/get-reports-metadata",
  REPORT_DOWNLOAD_URL: "/reports",
  REPORT_FILTER_DOWNLOAD_URL: "/filter-reports",

  /*Communication Routes*/
  COMMUNICATION_TEMPLATE_URL: "/admin/communication_templates",
  EVENT_TYPES_URL: "/admin/communication_template/event_types",
  RECIPIENT_GROUPS_URL: "/admin/recipient_groups",
  RECIPIENT_GROUPS_FILTER_META_URL: "/admin/recipient_groups/filter_meta",
  RECIPIENT_GROUP_USERS_URL: "/admin/recipient_groups/:recipientGroupId/users",

  /* Refund routes */
  REFUNDS_URL: "/admin/refunds",
  DOWNLOAD_RESIDENT_STATEMENTS_URL:
    "/admin/resident-accounts/download-statement",

  /* Payment routes */
  PAYMENTS_URL: "/admin/payments",

  /* Building Configuration Routes */
  BUILDINGS_URL: "/admin/buildings",
  BUILDING_PAYMENT_TYPES_URL: "/admin/building_payment_types",
  BLOCKS_URL: "/admin/blocks",
  FLOORS_URL: "/admin/floors",
  APARTMENTS_URL: "/admin/apartments",
  ROOMS_URL: "/admin/rooms",
  ROOM_STATUSES_URL: "/admin/rooms/room_statuses",
  BEDS_URL: "/admin/beds",
  BED_STATUSES_URL: "/admin/beds/bed_statuses",
  FLOOR_TYPES_URL: "/admin/floor_types",
  APARTMENT_TYPES_URL: "/admin/apartment_types",
  ROOM_TYPES_URL: "/admin/room_types",
  ROOM_TYPES_METADATA:"/admin/rooms_type_metatata",

  /**
   * Generate Payment Link
  */
  GENERATE_LONG_LINK : '/admin/payments/generate-long-link',

  /* Customers routes */
  RESIDENTS_URL: "/admin/residents",
  RESIDENT_PROFILES_URL: "/admin/resident-profiles/:residentId",
  RESIDENT_GUARANTOR_UPDATE_URL:
    "/admin/resident-profiles/:residentId/update-guarantor",
  RESIDENT_PROFILE_PIC_UPDATE_URL:
    "/admin/resident-profiles/:residentId/update-profile-pic",
  RESIDENT_BLACKLISTS_URL: "/admin/resident-blacklists",
  RESIDENT_DOCUMENTS_URL: "/admin/resident-documents",
  RESIDENT_DOCUMENTS_UPDATE_URL: "/admin/resident-documents/update/",
  ACTIVITY_LOGS_URL: "/admin/activity-logs",
  RESIDENT_DISCOUNTS_URL: "/admin/discounts",
  UPDATE_INVOICE_AMOUNT: "/admin/cleanup/update-invoice-amount",
  UPDATE_WALLET_AMOUNT: "/admin/cleanup/update-wallet",
  RESIDENT_DISCOUNTABLE_INVOICES_URL: "/discountable-invoices",
  DOWNLOAD_INVOICE_URL: "/admin/resident-accounts/download-invoice",
  CLEAR_UNPAID_INVOICES_URL: "/admin/clear-unpaid-invoices",

  /* Internal users routes */
  INTERNAL_USERS_URL: "/admin/internal-users",
  INTERNAL_USERS_UPDATE_PASSWORD_URL:
    "/admin/internal-users/:userId/update-password",
  INTERNAL_USERS_UPDATE_BLACKLIST_STATUS_URL:
    "/admin/internal-users/:userId/update-blacklist-status",

  /* Pricing configuration routes */
  PRICING_PLANS_URL: "/admin/pricing-plans",
  PRICING_PLAN_ROOM_TYPES_URL: "/admin/pricing-plan-room-type-maps",

  /* Agreement template routes */
  AGREEMENT_TEMPLATES_URL: "/admin/agreement-templates",
  AGREEMENT_LATEST_TEMPLATE_URL:
    "/admin/agreement-templates/get-latest-template",
  AGREEMENT_TEMPLATE_PREVIEW_URL:
    "/admin/agreement-templates/preview-template-changes",
  DOWNLOAD_RESIDENT_AGREEMENT_URL: "/admin/user-bookings/download-agreement",

  /* Notices routes */
  NOTICES_URL: "/admin/notices",

  /* Cleanup Routes */
  CleanUp: "/admin/cleanup",

  /*Dashboard*/
  STATS_URL: "/admin/analytics",
  THIRTY_DAYS_OCCUPANCY_URL: "/admin/analytics/chart/thirty_day_occupancy",
  REVENUE_CHART_URL: "/admin/analytics/chart/revenue",
  THREE_MONTHS_CHECKIN_CHECKOUTS_URL:
    "/admin/analytics/chart/checkins_checkouts",
  THIRTY_DAYS_MONTHLY_OCCUPANCY_URL:
    "/admin/analytics/chart/three_months_occupancy",
  MAINTENANCE_CHART_URL: "/admin/analytics/chart/maintenance_tickets",
  GENDER_PIE_CHART_URL: "/admin/analytics/chart/gender_demograph",
  CHECKIN_CHECKOUT_MTD_URL: "/admin/analytics/chart/checkins_checkouts_mtd",
  TOP_TEN_UNIV_URL: "/admin/analytics/chart/top_ten_universities",
  REGIONS_CHART_URL: "/admin/analytics/chart/regions",
  COLLECTION_CHART_URL: "/admin/analytics/chart/collection",
  CHECKOUT_BOXPLOT_URL: "/admin/analytics/chart/median_tenure",
});
