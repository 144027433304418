import {
  Component,
  ElementRef,
  NgZone,
  OnInit,
  OnDestroy,
  ViewChild,
  Inject
} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { CommonService } from '../../services/common.service';
import { DataService, Response } from '../../dataservice/data.service';
import { GlobalVariable } from '../../app.component';
import {LOCAL_STORAGE, WebStorageService} from 'angular-webstorage-service';
import { Location } from '@angular/common';

const SMALL_WIDTH_BREAKPOINT = 991;

export interface Options {
  heading?: string;
  removeFooter?: boolean;
}

@Component({
  selector: 'app-admin',
  templateUrl: './admin-layout.component.html',
 styleUrls: ['./admin-layout.component.css']
})
export class AdminLayoutComponent implements OnInit, OnDestroy {
  private _router: Subscription;
  private mediaMatcher: MediaQueryList = matchMedia(
    `(max-width: ${SMALL_WIDTH_BREAKPOINT}px)`
  );
  current_url: String;
  routeOptions: Options;
  page_no: number;
  private BASE_URL = GlobalVariable;
  outlet_id: String;
  options = {
    lang: 'en',
    theme: 'winter',
    settings: false,
    docked: false,
    boxed: false,
    opened: false
  };
  currentLang = 'en';
  @ViewChild('sidebar',{static: false})
  sidebar;
  @ViewChild('sidemenu',{static: false})
  sidemenu;
  public show: boolean = false;

  toggle() {
    this.show = !this.show;
  }

  constructor(
    @Inject(LOCAL_STORAGE) private storage: WebStorageService,
    private _element: ElementRef,
    private router: Router,
    private route: ActivatedRoute,
    public translate: TranslateService,
    private titleService: Title,
    zone: NgZone,
    private commonService: CommonService,
    private HttpService: DataService,
    private location: Location
  ) {
    this.commonService.sidebar_menu.asObservable().subscribe(res => {
      this.show=res;
    });
    this.mediaMatcher.addListener((mql: any) =>
      zone.run(() => {
        this.mediaMatcher = mql;
      })
    );
  }

  ngAfterViewInit(){
    this.addEventListenerToTheDom();
  }
  addEventListenerToTheDom(){
    window.addEventListener('click',()=>{
      if(this.show===true){
        this.show=false;
        this.commonService.show=false;
      }
    })
  }


  getClass(){
    if(this.show){
      return 'side-content collapse show'
    }else{
      return 'side-content collapse'
    }
  }

  ngOnInit(): void {
    this._router = this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        // Scroll to top on view load
      //  document.querySelector('.main-content').scrollTop = 0;
        this.runOnRouteChange();
      });
  }
  ngOnDestroy() {
    //this._router.unsubscribe();
  }

  runOnRouteChange(): void {
    this.route.children.forEach((route: ActivatedRoute) => {
      let activeRoute: ActivatedRoute = route;
      while (activeRoute.firstChild) {
        activeRoute = activeRoute.firstChild;
      }
      this.routeOptions = activeRoute.snapshot.data;
    });
    this.setTitle(this.routeOptions.heading);
  }

  setTitle(newTitle: string) {
    this.titleService.setTitle(
      'Qwetu | ' + newTitle
    );
  }

}
