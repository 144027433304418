import { Component, OnInit, Inject } from "@angular/core";
import { MenuService } from "./menu.service";
import { Router, ActivatedRoute, NavigationEnd } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { analyzeAndValidateNgModules } from "@angular/compiler";
import { CommonService } from "../../services/common.service";
import { LOCAL_STORAGE, WebStorageService } from "angular-webstorage-service";
import { DataService, Response } from "../../dataservice/data.service";
import { GlobalVariable } from "../../app.component";
import { Title } from "@angular/platform-browser";
import { filter } from "rxjs/operators";
import { Location } from "@angular/common";
export interface Options1 {
  heading?: string;
  removeFooter?: boolean;
}

@Component({
  selector: "app-menu",
  templateUrl: "./menu.component.html",
  styleUrls: ["./menu.component.css"],
  providers: [MenuService],
})
export class MenuComponent implements OnInit {
  private BASE_URL = GlobalVariable;
  menuItems: any = [];
  _router: any;
  routeOptions: Options1;
  routePage: any;
  constructor(
    @Inject(LOCAL_STORAGE) private storage: WebStorageService,
    private location: Location,
    public menuService: MenuService,
    private titleService: Title,
    public translate: TranslateService,
    private router: Router,
    private route: ActivatedRoute,
    private commonservice: CommonService,
    private HttpService: DataService
  ) {}

  ngOnInit() {
    const user = JSON.parse(localStorage.getItem("user_details"));
    const userRole = user["user_role"];
    const isUserASuperAdmin = userRole.id == 6;
    const SuperAdminAccessMenuItem = {
      title: "Internal Users",
      route: "/internal-users",
      svg: "assets/sprites/internal_users.svg#internal_users",
    };



const AdminMenu=[
  {
    title: "Customers",
    route: "/customers",
    svg: "assets/sprites/customers.svg#customers",
  },
  {
    title: "Report",
    route: "/report",
    svg: "assets/sprites/report.svg#report",
  },
  {
    title: "Dashboard",
    route: "/dashboard",
    svg: "assets/sprites/dashboard.svg#dashboard",
  },
  {
    title: "Payment",
    route: "/payment",
    svg: "assets/sprites/payment.svg#payment",
  },
  {
    title: "Refunds",
    route: "/refunds",
    svg: "assets/sprites/refund.svg#refund",
  },
  
  
]
    const menu = [
      {
        title: "Building Configuration",
        route: "/building-configuration",
        svg: "assets/sprites/building_configuration.svg#building_configuration",
      },
      {
        title: "Pricing Configuration",
        route: "/pricing-configuration",
        svg: "assets/sprites/pricing_configuration.svg#pricing_configuration",
      },
      {
        title: "Meta Configuration",
        route: "/meta-configuration",
        svg: "assets/sprites/meta_configuration.svg#meta_configuration",
      },
      {
        title: "Agreement Template",
        route: "/agreement-template",
        svg: "assets/sprites/agreement_template.svg#agreement_template",
      },
      {
        title: "Notice Board",
        route: "/notice-board",
        svg: "assets/sprites/notice_board.svg#notice_board",
      },
      {
        title: "Customers",
        route: "/customers",
        svg: "assets/sprites/customers.svg#customers",
      },
      {
        title: "Communication",
        route: "/communication",
        svg: "assets/sprites/communication.svg#communication",
      },
      {
        title: "Recipient Groups",
        route: "/recipient-groups",
        svg: "assets/sprites/recipient_groups.svg#recipient_groups",
      },
      {
        title: "Report",
        route: "/report",
        svg: "assets/sprites/report.svg#report",
      },
      {
        title: "Dashboard",
        route: "/dashboard",
        svg: "assets/sprites/dashboard.svg#dashboard",
      },
      {
        title: "Payment",
        route: "/payment",
        svg: "assets/sprites/payment.svg#payment",
      },
      {
        title: "Refunds",
        route: "/refunds",
        svg: "assets/sprites/refund.svg#refund",
      },
      {
        title: "Cleanup",
        route: "/cleanup",
        svg: "assets/sprites/notice_board.svg#notice_board",
      },
    ];

    if (isUserASuperAdmin) {
      menu.splice(6, 0, SuperAdminAccessMenuItem);

      this.menuItems = menu;
    } else {
      menu.splice(5,1).splice(7,3)
      this.menuItems = [...AdminMenu];
    }
  }

  changeSidebarMenu(){
    this.commonservice.toggle();
}

  setTitle(title: string) {
    this.titleService.setTitle("Qwetu | " + title);
  }
}
